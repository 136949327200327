import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    presignFile,
} from 'Redux/modules/trainer/articles/actions';

import {
    list as listTags 
} from 'Redux/modules/trainer/articleTags/actions';

import {
    list as listCategories
} from 'Redux/modules/trainer/articleCategories/actions';

export default connect(
    state => ({
        tags: state.trainerArticleTags.list,
        categories: state.trainerArticleCategories.list,
    }),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            presignFile: dispatch(presignFile),
            listTags: dispatch(listTags),
            listCategories: dispatch(listCategories),
        },
    }),
)(Component);