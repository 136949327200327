import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_BLOG } from 'Consts/routes';
import { ENDPOINT_TRAINER_FILE_PRESIGN } from 'Consts/api';

import { fromSelectObject } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import Checkbox from 'Components/forms/Checkbox';

export default class TrainerArticleEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
            listTags: PropTypes.func.isRequired,
            listCategories: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        tags: PropTypes.object.isRequired,
        categories: PropTypes.object.isRequired,
    };

    state = {
        formState: {},
        selectedTags: {},
        selectedCategories: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));

        this.loadCategories();
        this.loadTags();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    dataToFormState = data => {
        return {
            ...data,
            authorId: data.author
                ? { value: data.author.id, label: getFullName(data.author).label }
                : null,
            // ...this.tagsToFormState()
            ...this.checkboxesToFormState('articleTags', 'tag', 'selectedTags'),
            ...this.checkboxesToFormState('articleCategories', 'category', 'selectedCategories'),
        };
    }

    formStateToData = formState => {
        return {
            ...formState,
            authorId: fromSelectObject(formState.authorId),
            imageId: formState.image ? formState.image.id : undefined,
            status: fromSelectObject(formState.status),
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;
        const { selectedTags, selectedCategories } = this.state;

        return actions.update({
            id: data.id,
            ...this.formStateToData(formState),
            selectedTags: selectedTags,
            selectedCategories: selectedCategories
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;
        const { selectedTags, selectedCategories } = this.state;

        return actions.create({
            ...this.formStateToData(formState),
            selectedTags: selectedTags,
            selectedCategories: selectedCategories
        }).then(() => {
            history.push(
                withVariables(
                    TRAINER_BLOG.path,
                    {}, 
                    {}
                )
            );
        });
    }

    loadTags = () => {
        const { actions } = this.props;

        actions.listTags();
    }

    loadCategories = () => {
        const { actions } = this.props;

        actions.listCategories();
    }
   
    renderCheckboxes = (elements, name, selectedElemsName) => {
        let result = [];

        elements.forEach(elem => (
            result.push(
                <Checkbox
                    key={elem.slug + '_' + name}
                    name={elem.slug + '_' + name}
                    label={elem.name}
                    className={name}
                    onChange={(value) => this.setState({
                        [selectedElemsName]: {
                            ...this.state[selectedElemsName],
                            [value.name]: value.value,
                        }
                    })}
                />
            )
        ))

        return result;
    }

    checkboxesToFormState = (name, key, selectedName) => {
        const { data } = this.props;
        let result = {};

        if (data && data[name]) {
            data[name].forEach(elem => (
                result = {
                    ...result,
                    [elem.slug + '_' + key]: true
                }
            ))
        }

        this.setState({
            [selectedName]: result
        });

        return result;
    }

    tagsToFormState = () => {
        const { data } = this.props;

        let tags = {};

        if (data && data.articleTags) {
            data.articleTags.forEach(tag => (
                tags = {
                    ...tags,
                    [tag.name + '_tag']: true
                }
            ))
        }

        this.setState({
            selectedTags: tags
        })

        return tags;
    }

    categoriesToFormState = () => {
        const { data } = this.props;

        let categories = {};

        if (data && data.categories) {
            data.categories.forEach(category => (
                categories = {
                    ...categories,
                    [category.name]: true
                }
            ))
        }

        this.setState({
            selectedCategories: categories
        })

        return categories;
    }

    render() {
        const { location, history, actions, tags, categories, data } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="trainer-article-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'title',
                            label: 'Tytuł',
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'lead',
                            label: 'Wstęp',
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'body',
                            label: 'Treść',
                            required: true,
                        }, {
                            type: 's3FileUpload',
                            name: 'image',
                            label: 'Zdjęcie',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_TRAINER_FILE_PRESIGN
                                        .replace('{type}', 'article'),
                                },
                            },
                        }, {
                            type: 'element',
                            name: 'tags',
                            children: (
                                <>
                                    <p className="label">Tagi artykułu: </p>
                                    <div className="tags-select selects">
                                        {this.renderCheckboxes(tags.elements, 'tag', 'selectedTags')}
                                    </div>
                                </>
                            )
                        }, {
                            type: 'element',
                            name: 'categories',
                            children: (
                                <>
                                    <p className="label">Kategorie artykułu: </p>
                                    <div className="categories-select selects">
                                        {this.renderCheckboxes(categories.elements, 'category', 'selectedCategories')}
                                    </div>
                                </>
                            )
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}