import { reduxRequest as request } from 'Services/Api';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_ARTICLE_TAGS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
        },
    }));
};