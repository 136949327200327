import { css } from '@emotion/core';

export default (props, state) =>
css`
    .selects {
        display: flex;

        > * {
            margin-right: 1em;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
`;
