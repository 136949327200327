import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/articles/Editor';

export const TAB_DATA = 'data';

export default class TrainerUserShopPageCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="trainer-user-effect-create"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Utwórz artykuł"
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane',
                            children: (
                                <Editor
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}